import React from "react";
import { Link } from "@mui/material";
import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledOL,
  StyledP,
  StyledUL,
  TextParagraph,
} from "./styles";

const options = {
  forceBlock: true,
  overrides: {
    a: { component: Link },
    h1: {
      component: (props): React.ReactElement => (
        <StyledH1 color="primary" variant="h1" {...props} />
      ),
    },
    h2: {
      component: (props): React.ReactElement => (
        <StyledH2 color="primary" variant="h2" {...props} />
      ),
    },
    h3: {
      component: (props): React.ReactElement => (
        <StyledH3 color="primary" variant="h3" {...props} />
      ),
    },
    ol: {
      component: (props): React.ReactElement => (
        <StyledOL color="primary" component="ol" variant="body1" {...props} />
      ),
    },
    p: {
      component: (props): React.ReactElement => (
        <StyledP color="primary" variant="body1" {...props} />
      ),
    },
    ul: {
      component: (props): React.ReactElement => (
        <StyledUL color="primary" component="ul" variant="body1" {...props} />
      ),
    },
  },
};

type PpTextParagraphBlock = {
  text: string;
};

export function TextParagraphBlock({
  text,
}: PpTextParagraphBlock): React.ReactElement {
  return <TextParagraph options={options}>{text}</TextParagraph>;
}
